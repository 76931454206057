export const useModalStore = defineStore({
  id: "modal-store",
  state: () => {
    return {
      show: false,
    };
  },
  actions: {
    showModal(): void {
      this.show = true;
    },
    hideModal(): void {
      this.show = false;
    },
  },
});
